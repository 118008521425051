import { Seo } from '@/components';
import ChatProvider from '@/contexts/chat';
import { CadastroType } from '@/layouts/Cadastro/Form/Form';
import Page from '@/layouts/Cadastro/Page/Page';
import React from 'react';
import { Helmet } from 'react-helmet';

const googleRecaptchaIDEmail = process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY_V3_EMAIL;

const Cadastro = () => {
  return (
    <>
      <Seo />
      <Helmet>
        <script
          async
          src={`https://www.google.com/recaptcha/api.js?render=${googleRecaptchaIDEmail}`}
        ></script>
      </Helmet>
      <ChatProvider>
        <Page type={CadastroType.SOLICITAR_CONTATO} />
      </ChatProvider>
    </>
  );
};

export default Cadastro;
